.contact-wrapper {
    margin: 45px calc(80px + 3rem);
    color: #000;
}
.contact-wrapper h4{ 
    color: #fd9042;
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #2a2c39;
}
.contact-details {
    display: grid;
    grid-template-columns: repeat(2,1fr);
}

.contact-wrapper .detail {
   margin: 2rem 0;
   display: flex;
   align-items: center;
   cursor: pointer;
   transition: 0.5;
}
.contact-details .detail:hover svg {
    color: #008374;
}
.contact-details .detail:hover .round {
    background-color: #f6f6f6;
}
.contact-details .round {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.contact-wrapper svg {
    font-size: 24px;
}
.contact-wrapper p {
    padding: 0 0 0 60px;
    margin-bottom: 0;
    font-size: 14px;
    color: #555974;
}
@media (max-width: 1024px) {
    .contact-details {
        grid-template-columns: 1fr;
    }
}
@media (max-width: 900px) {
   .contact-wrapper {
    margin: 45px;
   }
}
@media (max-width:500px) {
    .contact-wrapper .detail {
        width: 300px;
    }
}