.service-contaner {
    padding: 45px calc(80px + 3rem);
    background: #f6f6f6;
    color: #000;
}
.service-contaner .heading-wrapper h4 {
    color: #fd9042;
}
.services-wrapper {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 2rem;
    margin: 45px 0;
}
.service-item {
    padding: 40px;
    background: #fff;
    border-radius: 10px;
    width: 150px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
}
.service-item h1 {
    color: #000;
    font-weight: 700;
    margin: 0 0 20px 0;
    padding-bottom: 8px;
    font-size: 18px;
    position: relative;
    display: inline-block;
    border-bottom: 4px solid #eeeeee;
    transition: 0.3s;
}
.service-contaner .icon {
    color: #b6b6b6;
}
.service-contaner .service-item:hover .icon {
    color: #008374;
}
.service-contaner .service-item:hover h1 {
    border-bottom: 4px solid #008374;
}
@media (max-width: 1200px) {
    .service-contaner {
        margin: 45px auto;
    }
    .services-wrapper {
        grid-template-columns: repeat(3,1fr);
    }
}
@media (max-width: 900px) {
    .services-wrapper {
        grid-template-columns: repeat(2,1fr);
    }
}
@media (max-width: 700px) {
    .service-contaner {
        padding: 45px;
    }
    .services-wrapper {
        gap: 1rem;
    }
    .service-item {
        width: 120px;
    }
}
@media (max-width: 500px) {
    .service-item {
        padding: 20px;
    }
}