.card {
  width: 250px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  background-color: #295c61;
  font-weight: bolder;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.card-container {
  padding: 10px 16px;
}
.card-container p {
  text-transform: capitalize;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px;
  margin-bottom: 2rem; 
}
h4 {
  margin: 0;
}

.card img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 5px 5px 0 0;
  object-fit: contain;
}
.topic-card-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 4rem 0;
  margin-bottom: 2rem;
  background-color: #EDF1D6;
}
.column-wrapper {
  display: grid;
  align-items: center;
  justify-content: center;
  /* grid-template-rows: 60%; */
}

@media (max-width:1024px) {
  .topic-card-wrapper {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width:768px) {
  .topic-card-wrapper {
    grid-template-columns: 1fr 1fr;
  }
  .card img {
    height: 200px;
  }
}

/* doctor card */
.account-wrapper {
  max-width: 200px;
  box-shadow: 0 0 29px 0 rgba(0, 0, 0, 0.533);
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  /* margin-left: 20px; */
  align-items: center;
  background: #1a2049;
  background: #008374;
  padding: 40px 30px;
  border-radius: 6px;
  flex-grow: 1;
  max-height: 430px;
  margin: 0 auto;
  cursor: pointer;
  transition: all 0.2s;
}
.account-wrapper:hover {
  transform: scale(1.1);
}
.account-title {
  text-transform: capitalize;
  font-size: 0.8rem;
}
.account-profile {
  margin: auto;
  position: relative;
  text-align: center;
  position: relative;
}
.account-profile img {
  width: 84px;
  height: 84px;
  border-radius: 50%;
  object-fit: cover;
  object-position: left;
  border: 3px solid #ffffff;
  padding: 5px;
}
.account-profile .blob {
  position: absolute;
  border-radius: 50%;
  animation: fly 5.8s linear infinite alternate;
}
.account-profile .blob:nth-child(1) {
  width: 14px;
  height: 14px;
  top: 25px;
  left: -20px;
  background: #28327a;
  animation-delay: 0.9s;
}
.account-profile .blob:nth-child(2) {
  width: 18px;
  height: 18px;
  background: #87344c;
  right: -20px;
  top: -20px;
  animation-delay: 0.2s;
}
.account-profile .blob:nth-child(3) {
  width: 12px;
  height: 12px;
  background: #13645b;
  right: -35px;
  top: 50%;
  animation-delay: 1.8s;
}
@media (max-width:1024px) {
  .topic-card-wrapper {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width:768px) {
  .topic-card-wrapper {
    grid-template-columns: 1fr 1fr;
  }
  .card img {
    height: 200px;
  }
}
@media (max-width:550px) {
.account-wrapper {
  max-width: 130px;
  margin-bottom: 1rem;
}
.all-doctors {
  margin: 0;
  gap: 0;
}
}