/* //courses-cards */

.course-card {
    float: left;
    padding: 0 1.7rem;
    width: 100%;
}

/* .row {
    max-width: 900px;
    margin: 50px auto 0;
} */

.course-card .menu-content {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.course-card .menu-content::before,
.course-card .menu-content::after {
    content: '';
    display: table;
}

.course-card .menu-content::after {
    clear: both;
}

.course-card .menu-content li {
    display: inline-block;
}

.course-card .menu-content a {
    color: #fff;
}

.course-card .menu-content span {
    position: absolute;
    left: 50%;
    top: 0;
    font-size: 10px;
    font-weight: 700;
    font-family: 'Open Sans';
    transform: translate(-50%, 0);
}

.course-card .wrapper {
    background-color: #295c61;
    min-height: 350px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.2);
    background-size: contain;
    background-position: top;
}

.course-card .wrapper:hover .data {
    transform: translateY(0);
}

.course-card .data {
    position: absolute;
    bottom: 0;
    width: 100%;
    transform: translateY(calc(70px + 1em));
    transition: transform 0.3s;
}

.course-card .data .content {
    padding: 1em;
    position: relative;
    z-index: 1;
    background-color: #008374;
    color: #fff;
}

.course-card .author {
    font-size: 12px;
}

.course-card .title {
    margin-top: 10px;
    margin-bottom: 2rem;
}

.course-card .text {
    height: 70px;
    margin: 0;
}

.course-card input[type='checkbox'] {
    display: none;
}

.course-card input[type='checkbox']:checked+.menu-content {
    transform: translateY(-60px);
}
.card-wrapper .date {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #008374;
    color: #fff;
    padding: 0.8em;
}
.card-wrapper .date span {
    display: block;
    text-align: center;
}
.card-wrapper .date .price {
    font-weight: 700;
    font-size: 24px;
}
.card-wrapper .date .month {
    text-transform: uppercase;
}
.card-wrapper .date .month, .card-wrapper .date .year {
    font-size: 12px;
}
.card-wrapper .content {
    background-color: #fff;
    box-shadow: 0 5px 30px 10px rgba(0, 0, 0, 0.3);
}
.card-wrapper .title a {
    color: #fff;
}
.card-wrapper .menu-button {
    position: absolute;
    z-index: 999;
    top: 16px;
    right: 16px;
    width: 25px;
    text-align: center;
    cursor: pointer;
    width: 50px;
    padding: 0.5rem;
    background: #fff;
    color: #000;
    border-radius: 5px;
    font-weight: bolder;
}
.card-wrapper .menu-button span::after, .card-wrapper .menu-button span::before {
    content: '';
    display: block;
    width: 5px;
    height: 5px;
    background-color: currentColor;
    position: absolute;
    border-radius: 50%;
}
.card-wrapper .menu-button span::before {
    left: -10px;
}
.card-wrapper .menu-button span::after {
    right: -10px;
}
.card-wrapper .menu-content {
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    transition: transform 0.3s;
    transform: translateY(0);
}
.card-wrapper .menu-content li {
    width: 33.333333%;
    float: left;
    background-color: #77d7b9;
    height: 60px;
    position: relative;
}
.card-wrapper .menu-content a {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
}
.card-wrapper .menu-content span {
    top: -10px;
}

@media (max-width : 768px) {
  .course-card {
    width: 90%;
  }
}