.footer-container {
    background: #008374;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 45px calc(80px + 3rem);
    padding-bottom: 10px;
    position: static;
    bottom: 0;
}
.tags {
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    font-weight: bold;
}
.tags:hover {
    font-size: 14px;
}
.top-footer {
    width: 100%;
    display: flex;
}
.top-footer span {
    margin-left: 1rem;
}
.connect-footer {
    width: 40%;
    text-align: left;
}
.additional-footer {
    width: 60%;
    display: flex;
    justify-content: space-around;
    text-align: left;
}
.useful-links {
    cursor: pointer;
}
.footer-container .connect-footer p {
    font-size: 14px;
}
.useful-links h6 {
    font-size: 14px;
    margin: 1rem 0;
    color: rgb(236, 236, 236);
}
.useful-links h6:hover {
    color: rgb(255, 255, 255);
}
.footer-container .rights {
    font-size: 14px;
}
.footer-container p {
    font-size: 10px;
    margin: 2px 0;
}
.footer-content {
    margin-top: 2rem;
}
@media (max-width:1400px) {
    .footer-container {
        padding: 45px;
    }
}
@media (max-width:1150px) {
    .additional-footer {
        display: grid;
        grid-template-columns: repeat(3,1fr);
    }
}
@media (max-width:900px) {
    .top-footer {
        flex-direction: column;
    }
    .connect-footer {
        width: 80%;
        margin: 0 auto;
    }
    .additional-footer {
        width: 100%;
        padding: 2rem;
    }
}
@media (max-width:650px) {
    .footer-container {
        padding: unset;
    }
    .connect-links {
        width: 80%;
    }
}