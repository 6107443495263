@import url('https://fonts.googleapis.com/css?family=Arvo');
 @media screen and (min-width: 768px) {
	 .section {
		 padding: 4rem;
	}
}
@media (max-width: 768px) {
    .section {
        padding: 1rem;
    }
}
.section h1 {
	font-size: 2rem;
	margin: 0 0 1.5em;
	color: #000;
}
 .grid {
	 display: grid;
	 grid-gap: 30px;
	 grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	 grid-auto-rows: 150px;
	 grid-auto-flow: row dense;
}
 .item {
	 position: relative;
	 display: flex;
	 flex-direction: column;
	 justify-content: flex-end;
	 box-sizing: border-box;
	 background: #0c9a9a;
	 color: #fff;
	 grid-column-start: auto;
	 grid-row-start: auto;
	 color: #fff;
	 background-size: cover;
	 background-position: center;
	 box-shadow: -2px 2px 10px 0px rgba(68, 68, 68, 0.4);
	 transition: transform 0.3s ease-in-out;
	 cursor: pointer;
	 counter-increment: item-counter;
}
 .item:after {
	 content: '';
	 position: absolute;
	 width: 100%;
	 height: 100%;
	 background-color: black;
	 opacity: 0.3;
	 transition: opacity 0.3s ease-in-out;
}
 .item:hover {
	 transform: scale(1.05);
}
 .item:hover:after {
	 opacity: 0;
}
 .item--medium {
	 grid-row-end: span 2;
}
 .item--large {
	 grid-row-end: span 3;
}
 .item--full {
	 grid-column-end: auto;
}
 @media screen and (min-width: 768px) {
	 .item--full {
		 grid-column: -1;
		 grid-row-end: span 2;
	}
}
 .item__details {
	 position: relative;
	 z-index: 1;
	 padding: 15px;
	 color: #444;
	 background: #fff;
	 text-transform: lowercase;
	 letter-spacing: 1px;
	 color: #828282;
}
 .item__details:before {
	 content: counter(item-counter);
	 font-weight: bold;
	 font-size: 1.1rem;
	 padding-right: 0.5em;
	 color: #444;
}
.gallery-carousel {
	position: absolute;
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    background: #000000a3;
    z-index: 999;
}
.o-hd {
	overflow: hidden;
}
.video-grid {
	display: grid;
	grid-template-columns: repeat(2,1fr);
	gap: 1rem;
	justify-items: center;
}
@media (max-width: 1225px) {
	.video-grid {
		grid-template-columns: 1fr;
	}
}
 