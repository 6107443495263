.intro-banner {
    width: 100vw;
    height: max-content;
    background-color: #008374;
    color: #fff;
    padding: 45px 80px;
    display: grid;
    grid-template-columns: 50% 30%;
    /* gap: 10%; */
}
.info {
    padding: 2rem;
}
.info h1 {
    font-size: 48px;
}
.info p {
    margin: 2rem 0;
}
.info span {
    font-size: 16px;
    color: #e1e1e1;
    border-bottom: 4px solid #eeeeee;
    padding-bottom: 0.5rem;
}
.info span:hover {
    border-bottom: 4px solid #fd9042;
    cursor: pointer;
}
.common-btn {
    font-family: rgba(251, 249, 249, 0.394);
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 14px 40px;
    border-radius: 50px;
    transition: 0.3s;
    color: #fff;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
    border: 2px solid rgba(255, 255, 255, 0.1);
    cursor: pointer;
}
.icon{
    margin-bottom: 20px;
    padding-top: 10px;
    display: inline-block;
    transition: all 0.3s ease-in-out;
    font-size: 48px;
    line-height: 1;
    color: rgba(255, 255, 255, 0.6);
}
.services {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 1rem;
}
.service {
    padding: 60px 30px;
    position: relative;
    overflow: hidden;
    background: #008d7d;
    box-shadow: 0 0 29px 0 rgba(0, 0, 0, 0.08);
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
    z-index: 1;
    width: 150px;
    text-align: center;
    margin: 0 auto;
    font-weight: bold;
    cursor: pointer;
    transform: scale(0.9);
}
.service:hover {
    transform: scale(1);
}
.service:hover .icon {
    color: #fff;
}

@media (max-width: 1460px) {
    .service {
        padding: 40px 30px;
        width: 100px;
    }
}
@media (max-width: 1200px) {
    .intro-banner {
        padding: 45px 0px;
        grid-template-columns: 100%;
    }
    .services {
        grid-template-columns: repeat(6,1fr);
    }
}
@media (max-width: 1024px) {
    .services {
        grid-template-columns: repeat(4,1fr);
    }
    .service {
        padding: 60px 30px;
        width: 150px;
    }
}
@media (max-width: 900px) {
    .services {
        grid-template-columns: repeat(3,1fr);
    }
}
@media (max-width: 680px) {
    .services {
        grid-template-columns: repeat(2,1fr);
    }
}
@media (max-width: 500px) {
    .service {
        padding: 40px 10px;
    }
}