.course-detail-container img {
    width: 100%;
    aspect-ratio: 32/9;
    filter: brightness(0.3);
}

/* .banner-details {
    position: relative;
} */

.course-detail-container .showcase-wrapper{
    margin-bottom: 0;
    /* width: ; */
}
.course-detail-container .showcase-container {
width: 100%;
}
.course-detail-container .showcase-right {
    display: grid;
    grid-template-columns: 70% 30%;
    }
.content-wrap {
    width: 50%;
    display: flex;
    position: absolute;
    align-items: center;
    top: 0;
    padding: 3rem 20px;
    height: 100%;
}

.content-wrap h1,
.content-wrap p,
.content-wrap h5 {
    color: #000;
}
.content-wrap h3,h5 {
    font-size: 24px;
}

/* .content-container {
    margin-top: 95%;
} */



/*  */
.detail-card-container {
    /* width: 85%;
    margin: 45px auto;
    margin-top: 0;
    display: flex;
    justify-content: end; */
    /* position: absolute; */
}
.hide-details {
    display: none;
}
.buy-course {
    margin-bottom: 1.2rem;
    background: #fff;
    color: #000;
    width: 300px;
    padding: 1rem 40px;
    font-weight: bolder;
}

.buy-content {
    padding: 60px 30px;
    position: relative;
    overflow: hidden;
    background: #008d7d;
    box-shadow: 0 0 29px 0 rgba(0, 0, 0, 0.08);
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
    z-index: 1;
    /* width: 150px; */
    text-align: center;
    margin: 0 auto;
    font-weight: bold;
    cursor: pointer;
    transform: scale(0.9);
}
/* .detail-card-container:hover .buy-content {
    width: 300px;
    height: unset;
    padding: 40px;
} */

.buy-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 16px;
}

.buy-title {
    color: #ffffff;
    font-weight: 600;
    font-size: 24px;
}

.buy-author {
    margin-top: 3px;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.buy-sum {
    margin-top: 20px;
    font-size: 36px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-bottom: 0.5rem;
}

.buy-see {
    text-align: center;
    background-color: #fff;
    color: #252b3b;
    font-weight: 600;
    padding: 8px;
    font-size: 14px;
    width: 160px;
    border-radius: 20px;
    cursor: pointer;
    margin: 0 auto;
}
.buy-content img {
    object-fit: contain;
    height: 100%;
}
@media (max-width: 1100px) {
    .course-detail-container .showcase-right {
        grid-template-columns: 60% 40%;
        }
}
@media (max-width: 768px) {
    .detail-card-container {
        position: relative;
        justify-content: center;
    }
    .course-detail-container .showcase-right {
        grid-template-columns: 1fr;
        }
}