.gmap_canvas {overflow:hidden;background:none!important;height:300px;width:100%;display: flex;}
.mapouter{position:relative;text-align:right;height:300px;width:100%;}
.location {
  width: 100%;
  /* position: absolute; */
  /* bottom: 0;
  left: 0; */
}
.location .heading {
  display: flex;
  align-items: baseline;
  font-size: 24px;
  gap: 1rem;
  margin-left: 2rem;
}
@media (max-width: 900px) {
  .gmap_canvas {
    width: max-content;
    display: flex;
    margin: 0 auto;
  }
}
@media (max-width: 650px) {
  .mapouter {
    width: 60%;
  }
 .gmap_canvas {
  width: unset;
 }
}