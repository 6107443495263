.books-wrapper {
    position: relative;
}
.book-container {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: -55%;
    background: rgba(0, 0, 0, 0.453);
    /* margin-top: -4rem; */
    padding: 2rem;
    z-index: 9999;
}
.books-wrapper .column-wrapper {
    grid-template-rows: unset;
}
.books-wrapper .column-wrapper .card img {
    width: 80%;
    height: 250px;
    display: flex;
    border-radius: 5px 5px 0 0;
    object-fit: contain;
    margin: 0 auto;
}
embed {
    margin: 0 25%;
    height: 90%;
}
@media (max-width: 1200px) {
    embed {
        margin: 0 12%;
    }
}
@media (max-width: 1020px) {
    embed {
        margin: 0;
        width: 100%;
    }
    .book-container {
        padding: 3rem 0;
    }
}