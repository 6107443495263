.slider-wrapper {
    width: 50%;
    margin: 4rem auto 2rem;
  }
  .slick-slide {
    text-align: center;
    position: relative;
  }
  .slick-slide:focus {
    outline: none;
  }
  .slick-slide-title {
    text-transform: capitalize;
  }
  .slick-slide-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3),
      0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
  }
  .slick-slide-label {
    color: #fff;
    padding: 10px;
    position: absolute;
    left: 0px;
    font-size: 1.5em;
    bottom: 0px;
    width: 100%;
  }
  .slick-prev:before,
  .slick-next:before {
    color: #777777;
  }

  .slick-prev {
    left: -52px !important;
  }
  
  .slick-next:before,
  .slick-prev:before {
    content: "" !important;
  }
  .next-slick-arrow,
  .prev-slick-arrow {
    color: #000000;
    font-size: 48px;
  }

  .thumbnail-slider-wrap {
    margin-top: 15px;
    height: 85px;
  }
  .thumbnail-slider-wrap .slick-track .slick-slide {
    text-align: center;
  }
  .thumbnail-slider-wrap .slick-track .slick-slide img {
    width: 70%;
  }
  .slider-wrapper h1 {
    color: #000;
  }
  .gallery-wrapper {
    margin-top: 10rem;
  }