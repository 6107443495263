.thank {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.thank h1 {
    color: #000;
}
.thank h2 {
    color: #609966;
}
.thank span {
    font-size: 12px;
}