.doctor-profile {
    padding: 2rem;
}
.profile-card {
    width: 50%;
    height: 500px;
    border-radius: 6px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.293);
    padding: 40px 30px;
    margin: 0 auto;
    transition: all 0.2s;
    background: #008374;
}
.account-profile {
    margin: auto;
    position: relative;
    text-align: center;
    position: relative;
  }
  .account-profile img {
    width: 84px;
    height: 84px;
    border-radius: 50%;
    object-fit: cover;
    object-position: left;
    border: 3px solid #ffffff;
    padding: 5px;
  }
  .account-name {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 0.3rem;
  }
  .profile-details {
    width: 80%;
    margin: 2rem auto;
  }
  .profile-details .detail {
    border-top: 1px solid #fff;
  }
  .detail-snackbar {
    padding: 10px;
    display: flex;
    justify-content: space-between;
  }