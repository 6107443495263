.all-doctors {
    display: grid;
    align-items: center;
    justify-content: space-evenly;
    grid-template-columns: repeat(4,1fr);
    min-height: 300px;
    gap: 2rem;
    margin: 20px;
}
.all-doctors .card{
    cursor: pointer;
    padding: 1rem;
}
.team-title {
    font-size: 36px;
    font-weight: bolder;
    margin: 0 80px;
    color: #000;
}
@media (max-width : 1024px) {
    .all-doctors {
        grid-template-columns: repeat(2,1fr);
    }
}
@media (max-width: 768px) {
    .all-doctors .list-doctors {
        width: 200px;
        margin: 0 auto;
    }
    .all-doctors .card {
    padding: 0.5rem;
    }
    .card {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .team-title {
        margin: 0 20px;
    }
}
@media (max-width : 628px) {
    .all-doctors {
        grid-template-columns: repeat(2,1fr);
    }
}