.blue { color: #185875; }
.yellow { color: #FFF842; }

.table-container th h1 {
	  font-weight: bold;
	  font-size: 1em;
  text-align: left;
  color: #fff;
}

.table-container td {
	  font-weight: normal;
	  font-size: 1em;
  -webkit-box-shadow: 0 2px 2px -2px #0E1119;
	   -moz-box-shadow: 0 2px 2px -2px #0E1119;
	        box-shadow: 0 2px 2px -2px #0E1119;
}
.no-data {
	color: #000;
}

.table-container {
	  text-align: left;
	  overflow: hidden;
	  width: 80%;
	  margin: 0 auto;
  display: table;
  padding: 0 0 8em 0;
  margin: 2rem auto;
}

.table-container td, .table-container th {
	  padding-bottom: 2%;
	  padding-top: 2%;
  padding-left:2%;  
  width: 70px;
}
.table-container td button {
	color: #fff;
	border-radius: 2px;
	padding: 5px 10px;
}

/* Background-color of the odd rows */
.table-container tr:nth-child(odd) {
	  background-color: #323C50;
}

/* Background-color of the even rows */
.table-container tr:nth-child(even) {
	  background-color: #2C3446;
}

.table-container th {
	  background-color: #008374;
}

.table-container td:first-child { color: #ffffff; }

.table-container tr:hover {
   background-color: #464A52;
-webkit-box-shadow: 0 6px 6px -6px #78a595;
	   -moz-box-shadow: 0 6px 6px -6px #78a595;
	        box-shadow: 0 6px 6px -6px #78a595;
}

.table-container td:last-child:hover {
  background-color: #3C6255;
  color: #fff;
  font-weight: bold;
  
  box-shadow: #7F7C21 -1px 1px, #7F7C21 -2px 2px, #7F7C21 -3px 3px, #7F7C21 -4px 4px, #7F7C21 -5px 5px, #7F7C21 -6px 6px;
  transform: translate3d(6px, -6px, 0);
  
  transition-delay: 0s;
	  transition-duration: 0.4s;
	  transition-property: all;
  transition-timing-function: line;
}

@media (max-width: 800px) {
.table-container td:nth-child(4),
.table-container th:nth-child(4) { display: none; }
}