
#showcase h1 {
    font-size: 50px;
    margin-bottom: 20px;
    margin-top: 0;
  }
  
  .showcase-img img {
    /* width: 90%; */
    height: 100%;
    /* margin-left: 60px; */
  }

.showcase-wrapper {
    display: grid;
    width: 85%;
    margin: 45px auto;
    gap: 2rem;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    padding: 2rem;
    background-color: #008374;
    color: #fff;
}  
.showcase-container {
    width: 80%;
}
.showcase-container button {
padding: 10px 16px;
    border: 2px solid #fff;
    color: #fff;
}

@media (max-width: 768px) {
  .showcase-wrapper {
    grid-template-columns: 100%;
    width: 80vw;
  }
  .showcase-container {
    width: 100%;
  }
}
.treatment-description {
  margin-bottom: 4rem;
}

.heading-wrapper h4 {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0 0 5px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #d6d6d6;
  font-weight: bolder;
}
.heading-wrapper h4::after {
  content: "";
    width: 120px;
    height: 1px;
    display: inline-block;
    background: #fd9042;
    margin: 4px 10px;
}