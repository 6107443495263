.doctor-description button {
    padding: 10px 16px;
    border: 2px solid #000000;
    color: #000000;
}

.doctor-description {
    padding: 2rem;
    color: #000;
}

.topic-wrapper {
    display: flex;
    padding: 45px calc(80px + 3rem);
}

.doctor-meta-details {
    display: flex;
}

.doctor-meta-details p {
    margin: 0;
    color: grey;
    font-weight: bold;
    font-size: 10px;
    text-transform: capitalize;
}

.doctor-meta-details h1 {
    margin: 10px 0;
}

.doctor-meta-details img {
    width: 150px;
    margin-right: 1rem;
    border-radius: 50%;
    height: 150px;
}

.left-about-section {
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

@media (max-width: 1000px) {
    .topic-wrapper {
        grid-template-columns: 65% 35%;
    }
}

@media (max-width: 820px) {
    .topic-wrapper {
        grid-template-columns: 100%;
        justify-content: center;
    }

    .left-about-section {
        width: max-content;
        margin: 0 auto;
    }
}