@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700&display=swap");
 :root {
	 --color-black: #1a1a1a;
	 --color-darks: #404040;
	 --color-greys: #999;
	 --color-light: #f2f2f2;
	 --color-white: #fff;
	 --color-green-100: #578e75;
	 --color-green-200: #4e7e68;
	 --color-green-300: #008374;
	 --display-100: clamp(0.88rem, calc(0.8rem + 0.38vw), 1rem);
	 --display-200: clamp(1rem, calc(0.96rem + 0.18vw), 1.13rem);
	 --display-300: clamp(1.2rem, calc(1.11rem + 0.43vw), 1.5rem);
	 --shadow-small: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
	 --shadow-medium: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
	 --shadow-large: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
a, button {
	cursor: pointer;
	border: none;
	outline: none;
	background: none;
	text-transform: unset;
	text-decoration: none;
	font-weight: bolder;
}
ul li {
	list-style: none;
	font-weight: bolder;
}
 .container {
	 max-width: 100vw;
	 height: auto;
	 margin: 0 auto;
	 padding: 0 1.25rem;
	 box-shadow: 0 8px 23px -19px black
}
 .brand {
	width: 40px;
	 font-family: inherit;
	 font-size: 1.5rem;
	 font-weight: 600;
	 line-height: 1.5;
	 letter-spacing: -1px;
	 text-transform: uppercase;
	 color: var(--color-green-300);
}
.brand img {
	width: 100%;
	height: 100%;
}
 .header {
	 /* position: fixed;
	 left: 0;
	 top: 0; */
	 width: 100%;
	 height: auto;
	 z-index: 10;
	 margin: 0 auto;
	 background-color: var(--color-white);
	 box-shadow: var(--shadow-medium);
}
 .navbar {
	 display: flex;
	 flex-direction: row;
	 align-items: center;
	 justify-content: flex-start;
	 column-gap: 1rem;
	 width: 100%;
	 height: 4rem;
	 margin: 0 auto;
}
 .menu {
	 position: fixed;
	 top: 0;
	 left: -100%;
	 width: 80%;
	 height: 100%;
	 z-index: 10;
	 overflow-y: auto;
	 background-color: var(--color-white);
	 transition: all 0.45s ease-in-out;
	 display: flex;
}
 .menu.is-active {
	 top: 0;
	 left: 0;
	 color: var(--color-green-300);
}
 .menu-inner {
	 display: flex;
	 flex-direction: column;
	 row-gap: 1.25rem;
	 margin: 1.25rem;
	 position: relative;
}
.menu-options {
	position: absolute;
	z-index: 999;
	bottom: 0;
	color: #000;
}
 .menu-link {
	 font-family: inherit;
	 font-size: 12px;
	 font-weight: 500;
	 line-height: 1.5;
	 text-transform: uppercase;
	 color: var(--color-black);
	 transition: all 0.3s ease;
	 font-weight: bolder;
	 letter-spacing: 2px;
}
 .menu-block {
	 display: inline-block;
	 font-family: inherit;
	 font-size: var(--display-100);
	 font-weight: 500;
	 line-height: 1.25;
	 user-select: none;
	 text-align: center;
	 white-space: nowrap;
	 margin-left: auto;
	 padding: 0.65rem 1.35rem;
	 border-radius: 3rem;
	 color: var(--color-white);
	 background-color: var(--color-green-300);
	 box-shadow: var(--shadow-medium);
	 transition: all 0.3s ease-in-out;
	 cursor: pointer;
}
 @media only screen and (min-width: 48rem) {
	 .menu {
		 position: relative;
		 top: 0;
		 left: 0;
		 width: auto;
		 height: auto;
		 margin-left: auto;
		 background: none;
	}
	 .menu-inner {
		 display: flex;
		 flex-direction: row;
		 column-gap: 1.75rem;
		 margin: 0 auto;
	}
	 .menu-link {
		 text-transform: capitalize;
	}
	 .menu-block {
		 margin-left: 2rem;
	}
}
 .burger {
	 position: relative;
	 display: block;
	 cursor: pointer;
	 order: -1;
	 width: 1.75rem;
	 height: auto;
	 border: none;
	 outline: none;
	 visibility: visible;
}
 .burger-line {
	 display: block;
	 cursor: pointer;
	 width: 100%;
	 height: 2px;
	 margin: 6px auto;
	 transform: rotate(0deg);
	 background-color: var(--color-black);
	 transition: all 0.3s ease-in-out;
}
 @media only screen and (min-width: 48rem) {
	 .burger {
		 display: none;
		 visibility: hidden;
	}
}
 .overlay {
	 position: fixed;
	 top: 0;
	 left: 0;
	 width: 100%;
	 height: 100%;
	 z-index: 9;
	 opacity: 0;
	 visibility: hidden;
	 background-color: rgba(0, 0, 0, 0.65);
	 transition: all 0.3s ease-in-out;
}
 .overlay.is-active {
	 display: block;
	 opacity: 1;
	 visibility: visible;
}
.fix-animation {
	position: fixed;
    max-width: 98vw;
    height: auto;
    margin: 0 auto;
    padding: 0 1.25rem;
	top: 0;
    z-index: 99;
    width: 100%;
    background: #fff;
}

@media (max-width:768px) {
	.menu {
		flex-direction: column;
	}
}
 