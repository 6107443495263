.Courses-container {
    color: #ffffff;
    /* background-color: #49757e; */
    padding: 45px calc(80px + 3rem);
    /* margin-top: 90px; */
    color: #000;
}

.treatment-heading.admission h5 a {
    color: #fff;
}
.treatment-heading svg {
    color: #fff;
}
.admission {
    flex-direction: column;
    align-items: start;
}
.courses-cards {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 2rem;

}

.course-detail {
    padding: 1rem;
    margin-bottom: 60px;
    width: 100%;
}
.description {
    height: 120px;
    overflow: hidden;
    position: relative;
    /* box-shadow: 0px -30px 12px -10px rgba(85, 85, 85, 0.6) inset; */
    padding-bottom: 1rem;
}
.description span {
    position: absolute;
    bottom: 0;
    padding: 0 0.5rem;
    cursor: pointer;
    color: #fff;
    font-weight: bolder;
}
.h-c {
    height: 100%;
}
.course-section {
    margin-bottom: 2rem;
    border-bottom: 1px solid #252b3b;
}

@media (max-width: 1024px) {
    .courses-cards {
        grid-template-columns: repeat(2,1fr);
    }
}
@media (max-width :768px) {
    .Courses-container {
        padding: 1rem;
    }
    .courses-cards {
        grid-template-columns:1fr;
    }
}
.treatment-heading a {
    color: #000;
}