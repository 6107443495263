.appointment-wrapper {
  margin-top: 2rem;
  position: relative;
}
.appointment-upcomming {
position: absolute;
height: 100%;
width: 100%;
background-color: #ffffffbb;
z-index: 999;
}
/* 
body {
  background: #59ABE3;
  margin: 0
} */

.appointment-wrapper .form {
  width: 340px;
  height: 530px;
  /* background: #e6e6e6;
  border-radius: 8px;
  box-shadow: 0 0 40px -10px #000; */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  background: #fff;
  padding: 20px 30px;
  max-width: calc(100vw - 40px);
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  position: relative
}

.appointment-wrapper h2 {
  margin: 10px 0;
  padding-bottom: 10px;
  width: 220px;
  color: #78788c;
  border-bottom: 3px solid #008374;
  font-size: 18px;
  color: #609966;
}

.appointment-wrapper input {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  background: none;
  outline: none;
  resize: none;
  border: 0;
  font-family: 'Montserrat', sans-serif;
  transition: all .3s;
  border-bottom: 2px solid #bebed2
}

.appointment-wrapper input:focus {
  border-bottom: 2px solid #609966
}

.appointment-wrapper p:before {
  content: attr(type);
  display: block;
  margin: 28px 0 0;
  font-size: 14px;
  color: #5a5a5a
}

.appointment-wrapper button {
  float: right;
  padding: 8px 12px;
  margin: 8px 0 0;
  font-family: 'Montserrat', sans-serif;
  border: 2px solid #609966;
  background: 0;
  color: #5a5a6e;
  cursor: pointer;
  transition: all .3s
}

.appointment-wrapper button:hover {
  background: #609966;
  color: #fff
}

.appointment-wrapper .extra-contact-info {
  content: ' ';
  position: absolute;
  bottom: -15px;
  right: -20px;
  background: #50505a;
  color: #fff;
  width: 320px;
  padding: 16px 4px 16px 0;
  border-radius: 6px;
  font-size: 13px;
  box-shadow: 10px 10px 40px -14px #000;
  z-index: 99999;
}
.appointment-wrapper .extra-contact-info a {
  padding-left: 1.2rem;
}

.appointment-wrapper span {
  margin: 0 5px 0 15px
}
fieldset {
  display: block;
  margin: 28px 0 0;
  font-size: 14px;
  color: #5a5a5a;
  margin-bottom: 0.5rem;
}
.two-cols {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.two-cols label {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.query-container {
  margin: 45px calc(80px + 3rem);
}
.query-container span {
  color: #008374;
}

.query-container .heading {
  display: flex;
  align-items: baseline;
  /* font-size: 36px; */
  gap: 1rem;
  color: #000;
  /* margin-left: 2rem; */
}
.query-container h5 {
  color: #000;
  font-size: 18px;
}
.query-container .heading svg {
  font-size: 24px;
}
@media (max-width: 1530px) {
  .appointment-wrapper .form {
    width: 300px;
  }
  .appointment-wrapper .form fieldset {
    font-size: 10px;
  }
  .appointment-wrapper .extra-contact-info {
    width: 280px;
    padding: 12px 4px 16px 0;
  }
}
@media (max-width : 1400px) {
  .appointment-wrapper .extra-contact-info {
    right: -10px;
  }
}
@media (max-width: 1370px) {
  .right-section {
    padding: 1rem;
  }
}
@media (max-width: 1300px) {
  .appointment-wrapper .form {
    width: 280px;
    padding: 20px 20px;
  }
}
@media (max-width: 820px) {
  .appointment-wrapper .form {
    width: 340px;
  }
  .appointment-wrapper .form fieldset {
    font-size: 14px;
  }
  .appointment-wrapper .extra-contact-info {
    width: 280px;
    padding: 16px 4px 16px 0;
  }
  .query-container {
    margin: 45px;
  }
}