.treatment-container {
    color: #000;
    padding: 2rem;
}
.treatment-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 1rem;
}
.treatment-item {
    cursor: pointer;
    text-align: center;
    border: 1px solid #000;
    padding: 0.5rem;
    min-height: 80px;
}
.treatment-item h5 {
    color: #000;
    font-size: 16px;
}
.treatment-item:hover {
    background-color: #008374;
    color: #fff;
    border: 1px solid #fff;
}
.treatment-item:hover h5 {
    color: #fff;
}
.treatment-cards {
    margin-top: 4rem;
    cursor: pointer;
    color: #fff;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 1rem;
}
.treatment-heading {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.treatment-heading p {
    cursor: pointer;
}
.treatment-heading svg {
    font-size: 24px;
    color: #609966;
}
@media (max-width: 1250px) {
    .treatment-cards {
    grid-template-columns: repeat(3,1fr);
    }
}
@media (max-width:1024px) {
    .treatment-cards {
        grid-template-columns: repeat(2,1fr);
    }
}
@media (max-width:900px) {
    .treatment-grid {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}
@media (max-width:768px) {
    .treatment-container {
        padding: 1rem;
    }
    .treatment-grid {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .treatment-cards {
        grid-template-columns: repeat(2,1fr);
        }
        .card {
            width: 200px;
        }
}