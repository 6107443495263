@import url('https://fonts.googleapis.com/css2?family=Barlow&display=swap');
body {
  margin: 0;
  font-family:'Barlow', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #EDF1D6; */
  color: #fff;
  overflow-x: hidden;
  min-height: 100vh;
  position: relative;
}
html {
  overflow-x: hidden;

}
a {
  text-decoration: none;
  color: #000;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.app-container {
  width: 100vw;
  display: grid;
  grid-template-columns: 100%;
  min-height: calc(100vh - 300px);
}
/* .right-section {
  position: relative;
  background-color: #EEEEEE;
  padding: 2rem;
  background-color: #EEEEEE;
  color: #000;
  min-height: 1350px;
}
.right-section span {
  color: #609966;
} */
@media (max-width: 1200px) {
  .app-container {
    grid-template-columns: 100%;
  }
  .right-section {
    display: none;
  }
}
/* .gmap_canvas {overflow:hidden;background:none!important;height:510px;width:100%;}
.mapouter{position:relative;text-align:right;height:510px;width:100%;}
.location {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.location .heading {
  display: flex;
  align-items: baseline;
  font-size: 24px;
  gap: 1rem;
  margin-left: 2rem;
  margin-top: 2rem;
} */